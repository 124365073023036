import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';

import { EMPTY } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { SidebarActions } from './sidebar.actions';
import { ChatHistoryService } from 'src/app/core/api/chat-history.service';

import { Router } from '@angular/router';
import { Store } from '@ngrx/store';

import { selectExpanded } from './sidebar.selectors';
import daysToNow from 'src/app/components/sidebar/history/utils/daysToNow';
import { ChatActions } from '../chat/chat.actions';
import { selectMessages } from '../chat/chat.selectors';
import { compareAsc } from 'date-fns';
import { IReducerHistory } from './sidebar.state';

@Injectable()
export class SidebarEffects {
  constructor(
    private actions$: Actions,
    private chatHistoryService: ChatHistoryService,
    private router: Router,
    private store: Store
  ) {}

  fetchHistory$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        SidebarActions.requestHistory,
        SidebarActions.toggleExpanded,
        SidebarActions.close
      ),
      concatLatestFrom(() => this.store.select(selectExpanded)),
      mergeMap(([, expanded]) => {
        if (!expanded) {
          return this.chatHistoryService.getHistory().pipe(
            map((history) => {
              const historyWithDaysToNow = history.map((item) =>
                daysToNow(item)
              );
              const historyOrderedByDate = historyWithDaysToNow.sort(
                (a: IReducerHistory, b: IReducerHistory) => {
                  return compareAsc(
                    new Date(b.createdAt),
                    new Date(a.createdAt)
                  );
                }
              );

              return SidebarActions.requestHistorySuccess({
                history: historyOrderedByDate,
              });
            }),
            catchError(() => EMPTY)
          );
        }

        return EMPTY;
      })
    );
  });

  deleteHistoryItem$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SidebarActions.deleteHistoryItemRequest),
      mergeMap(({ conversationId }) =>
        this.chatHistoryService.deleteConversationById(conversationId).pipe(
          map(() => {
            this.router.navigateByUrl('/chat');

            return SidebarActions.deleteHistoryItemSuccess({
              conversationId,
            });
          }),
          catchError(() => EMPTY)
        )
      )
    );
  });

  updateHistory$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ChatActions.conversationCompleted),
      concatLatestFrom(() => this.store.select(selectMessages)),
      map(([conversationCompletedPayload, messages]) => {
        const conversationWithDaysToNow = daysToNow({
          id: conversationCompletedPayload.conversationId,
          title: messages[0].text,
          createdAt: new Date().toString(),
        });

        return SidebarActions.updateHistory(conversationWithDaysToNow);
      })
    );
  });

  deleteAllHistory$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SidebarActions.deleteAllHistoryRequest),
      mergeMap(() =>
        this.chatHistoryService.deleteAllHistory().pipe(
          map(() => {
            this.router.navigateByUrl('/chat');

            return SidebarActions.deleteAllHistorySuccess();
          }),
          catchError(() => EMPTY)
        )
      )
    );
  });
}
