import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { IConversationHistory } from 'src/app/core/api/chat-history.service';
import { ChatContext } from 'src/app/core/api/contexts.service';
import { ISuggestion, Widget } from 'src/app/core/api/socket.service';
import { IUser } from './chat.state';

export const ChatActions = createActionGroup({
  source: 'Chat Component',
  events: {
    connected: emptyProps(),
    disconnected: emptyProps(),
    createdRoom: props<{ conversationId: string }>(),
    sendMessageRequest: props<{
      message: string;
      actionFlow?: ISuggestion;
    }>(),
    sendMessageSuccess: props<{ message: string }>(),
    conversationCompleted: props<{
      message: string;
      conversationId: string;
      totalTokens: number;
      fromInternet: boolean;
      prompt: string;
      accessToken: string;
      suggestions: ISuggestion[];
      context?: string;
      widgets?: Widget[];
      messageId: string;
      userId: string;
    }>(),
    conversationWithError: emptyProps(),
    messageReceived: props<{
      chunk: string;
      messageId: string;
      question: string;
    }>(),
    getConversationByIdRequest: props<{ conversationId: string }>(),
    getConversationByIdSuccess: props<{
      conversation: IConversationHistory[];
      accessToken: string;
    }>(),
    submitFeedbackRequest: props<{
      feedback: {
        liked: boolean;
        rating?: string[];
        comment?: string;
        messageId: string;
        question: string;
        answer: string;
      };
    }>(),
    submitFeedbackSuccess: emptyProps(),
    createRoomAndSendMessageRequest: props<{ message: string }>(),
    createRoomAndSendMessageSuccess: props<{
      conversationId: string;
      message: string;
    }>(),
    setChatContext: props<{ context: ChatContext }>(),
    setContexts: props<{ contexts: ChatContext[] }>(),
    isUploadingFile: emptyProps(),
    uploadCompleted: emptyProps(),
    resetSuggestions: emptyProps(),
    userJoinedConversation: props<IUser>(),
    userLeavedConversation: props<{ id: string }>(),
    reset: emptyProps(),
    doNothing: emptyProps(),
  },
});
