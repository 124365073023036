import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { forkJoin, map } from 'rxjs';
import { ModalService } from 'src/app/modal/services/modal.service';
import { selectConversationId } from 'src/app/store/chat/chat.selectors';
import { ChatActions } from '../../../store/chat/chat.actions';
import { SidebarActions } from '../../../store/sidebar/sidebar.actions';
import {
  selectExpanded,
  selectHistory,
  selectShowDeleteButtons,
} from 'src/app/store/sidebar/sidebar.selectors';
import { ChatHistoryService } from 'src/app/core/api/chat-history.service';

@Component({
  selector: 'history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss'],
})
export class HistoryComponent implements OnInit {
  constructor(
    protected modalService: ModalService,
    private readonly store: Store,
    private router: Router,
    private chatHistoryService: ChatHistoryService
  ) {}

  history$ = this.store.select(selectHistory);
  isOpen$ = this.store.select(selectExpanded);
  showDeleteButtons$ = this.store.select(selectShowDeleteButtons);
  selectedConversationId$ = this.store.select(selectConversationId);

  id = '';

  ngOnInit(): void {
    this.store.dispatch(SidebarActions.requestHistory());
  }

  private portrait768px = 768;
  mobile: boolean = window.screen.width <= this.portrait768px;

  @HostListener('window:resize', [])
  onResize() {
    this.mobile = window.screen.width <= this.portrait768px;
  }

  toggleExpanded() {
    this.store.dispatch(SidebarActions.toggleExpanded());
  }

  onDelete(selectedToken?: string) {
    this.store.dispatch(
      SidebarActions.setShowDeleteButtons({ showDeleteButtons: true })
    );
    selectedToken &&
      this.store.dispatch(
        SidebarActions.setSelectedConversationId({
          selectedConversationId: selectedToken,
        })
      );
  }

  openModal(selectedToken: string) {
    this.modalService.open(selectedToken);
  }

  confirmDelete(conversationId: string) {
    this.store.dispatch(
      SidebarActions.deleteHistoryItemRequest({ conversationId })
    );
  }

  handleNewChat() {
    this.router.navigateByUrl('/chat');

    this.store.dispatch(ChatActions.reset());

    this.closeSidebarOnMobile();
  }

  cancelDelete() {
    this.store.dispatch(
      SidebarActions.setShowDeleteButtons({ showDeleteButtons: false })
    );
  }

  private closeSidebarOnMobile() {
    if (this.mobile) {
      this.store.dispatch(SidebarActions.close());
    }
  }

  downloadChatHistory() {
    this.history$
      .pipe(
        map((history) => {
          const getAllConversations = history.map((item) =>
            this.chatHistoryService.getConversationById(item.id)
          );

          forkJoin(getAllConversations).subscribe((conversations) => {
            this.downloadObjectAsJson(conversations);
          });
        })
      )
      .subscribe()
      .unsubscribe();
  }

  private downloadObjectAsJson(object: object) {
    const data =
      'data:text/json;charset=utf-8,' +
      encodeURIComponent(JSON.stringify(object));
    const link = document.createElement('a');

    link.href = data;
    link.download = 'history.json';
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  deleteHistory() {
    this.store.dispatch(SidebarActions.deleteAllHistoryRequest());
  }
}
